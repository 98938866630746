import React, { useCallback, useState } from "react";
import { observer } from "mobx-react";

import { Button, Card, CardActions, CardContent, Dialog, Box, Typography } from "@mui/material";

import useStores from "~/hooks";
import { CalendarMonth } from "@mui/icons-material";
import Translator from "../../components/Translator";
import FacilityRender from "../facilities/FacilityRender";
import Form from "../../components/Form";

/**
 * Карточка незапланированных ещё объектов моделирования.
 *
 * Отображается списком в нижней части экрана планирования отрисовки.
 */
const RenderingCard = observer(({ facility }) => {
  // TODO: нужно показать сканировщика
  // TODO: нужно показать даты завершения сканирования
  // TODO: нужно показать ссылку на облако

  const { facilityStore } = useStores();
  const { isPending, isPendingRenderingPlans, addModellingFormConfig } = facilityStore;

  const [modalVisible, setModalVisible] = useState(false);
  const [isFormPending, setIsFormPending] = useState(false);

  const onOpenDialog = useCallback(() => {
    setModalVisible(true);
  }, [setModalVisible]);

  const onCloseModal = useCallback(() => {
    setModalVisible(false);
  }, [setModalVisible]);

  const onSubmit = useCallback(
    async (data) => {
      setIsFormPending(true);
      await facilityStore.addNewModellingPlan(data);
      setIsFormPending(false);
      onCloseModal();
    },
    [onCloseModal, facilityStore]
  );

  return (
    <Card
      sx={{
        m: "0.125rem",
        width: "20rem",
        display: "flex",
        flexDirection: "column",
        flexShrink: 0,
      }}
    >
      <CardContent sx={{ flex: 1 }}>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "start", color: "blue", mb: "1rem" }}>
          <Typography variant="h5">{facility.contractInfo?.number}</Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "end", justifyContent: "end", color: "blue", mb: "1rem" }}>
          <Typography variant="body1">{facility.salesManager?.lastname} {facility.salesManager?.firstname}</Typography>
          <Typography variant="body2">{facility.modellingManager?.lastname} {facility.modellingManager?.firstname}</Typography>
        </Box>
        <FacilityRender isFull={true} facility={facility} />
      </CardContent>
      <CardActions>
        <Button
          key={"planModelling"}
          variant="outlined"
          startIcon={<CalendarMonth />}
          color="success"
          disabled={isPending || isPendingRenderingPlans}
          onClick={onOpenDialog}
        >
          <Translator text={"Plan modelling"} />
        </Button>
      </CardActions>
      <Dialog open={modalVisible} onClose={onCloseModal}>
        <Form
          isPending={isFormPending}
          config={addModellingFormConfig}
          onSubmit={onSubmit}
          onCancel={onCloseModal}
          initialValues={{ facilityId: facility.id }}
        />
      </Dialog>
    </Card>
  );
});

export default RenderingCard;
