import React, { useCallback, useState } from "react";
import { observer } from "mobx-react";

import { CssBaseline, Box, Card, CardHeader, Alert } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import { LoginForm } from "../components";

/**
 * Страница входа в приложение.
 */
const LoginPage = observer(({ store, theme, renderError }) => {
  const [error, setError] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false); // состояние для блокировки полей
  const [loginData, setLoginData] = useState({ login: "", password: "" });

  // Обработать логин, useCallback нужен чтобы стор успел создаться
  // const { isPending } = store;

  const handleLogin = useCallback(
    async (data) => {
      setIsDisabled(true);

      try {
        await store.login(data);
        setError(null);
        setIsDisabled(false);
      } catch (err) {
        if (err.message === "401") {
          setError("Invalid login or password.");
        } else {
          setError("Something went wrong. Please try again.");
        }
        setIsDisabled(false);
        setLoginData({ login: "", password: "" });
      }
    },
    [store]
  );

  const config = {
    submitText: "Login",
    fields: [
      {
        name: "login",
        title: "Login",
        type: "text",
        viewConfig: "input",
        isRequired: true,
        isReadOnly: isDisabled,
        validate: true,
        value: loginData.login,
        onChange: (e) => setLoginData({ ...loginData, login: e.target.value }),
      },
      {
        name: "password",
        title: "Password",
        type: "password",
        isRequired: true,
        isReadOnly: isDisabled,
        validate: false,
        value: loginData.password,
        onChange: (e) => setLoginData({ ...loginData, password: e.target.value }),
      },
    ],
  };

  // TODO: переделать на FormDialog
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex", flexGrow: 1 }}>
        <CssBaseline />
        {renderError}
        <Box
          component="main"
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            p: 0,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Card>
            <CardHeader sx={{ backgroundColor: "#0e77d3", color: "#fff" }} title={"ScanM2 ERP"} />
            {/* <AppBar
              sx={{
                position: "relative",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                maxWidth: "100%",
              }}
              enableColorOnDark={true}
            > */}
            {/* <Toolbar> */}
            {/* <ToolbarLogo /> */}
            {/* </Toolbar> */}
            {/* </AppBar> */}
            {/* <CardContent> */}
            {error && <Alert severity="error">{error}</Alert>}
            <LoginForm handleLogin={handleLogin} config={config} isPending={isDisabled} />
            {/* </CardContent> */}
          </Card>
        </Box>
      </Box>
    </ThemeProvider>
  );
});

export default LoginPage;
