import React, { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";

import { Box, Toolbar, Tooltip, Typography } from "@mui/material";
import { DataGrid } from "~/components/grid";

import useStores from "~/hooks";

import AddPayment from "./AddPayment";
import BarChart from "../../components/BarChart";
import DownloadInvoice from "./DownloadInvoice";
import DeleteInvoice from "./DeleteInvoice";
import { Add } from "@mui/icons-material";
import Form from "../../components/Form";

import { Module, DateRangePicker, SearchField, Translator } from "~/components";
import { StatesSelect } from "./components";

import "~/modules/payments/datagrid.css";

/**
 * Таблица инвойсов с пагинацией и опцией добавить каждому платёж.
 */
const Invoices = observer(() => {
  const { invoiceStore, branchStore, contractStore, rootStore, languageStore } = useStores();
  const { branch } = branchStore;
  const { permissionsSet } = rootStore;
  const { invoicesArray, paymentsArrayLength } = invoiceStore;
  const { activeContractsArray } = contractStore;

  const [isPending, setIsPending] = useState(false);
  const [searchBy, setSearchBy] = useState("");
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 20 });

  /** Управление списком состояний платежа */
  const [selectedStates, setSelectedStates] = useState([]);
  const onSelectStates = useCallback((e) => {
    const {
      target: { value },
    } = e;
    setSelectedStates(value);
  }, []);

  // Сделать поиск по инвойсам
  const onSearch = useCallback((value) => {
    if (value.length > 2) setSearchBy(value);
    else setSearchBy("");
  }, []);

  const [open, setOpen] = useState(false);
  const [isFormPenfing, setIsFormPending] = useState(false);
  const [formKind, setFormKind] = useState("invoice");
  const [formErrors, setFormErrors] = useState({});
  const [newInvoiceId, setNewInvoiceId] = useState("");

  useEffect(() => {
    if (paginationModel.pageSize < 1) return;
    setIsPending(true);
    const fetchData = async () => {
      await invoiceStore.getInvoices(branch, searchBy, paginationModel, selectedStates);
    };
    if (!!branch) fetchData();
    setIsPending(false);
  }, [branch, searchBy, paginationModel, invoiceStore, selectedStates]);

  // const downloadInvoiceFormConfig = useMemo(() => {
  //   return {
  //     submitText: "Download",
  //     cancelText: "Close",
  //     formTitle: "Download invoice",
  //     formText: "Invoice created successfully. Feel free to download the invoice or close this window",
  //     fields: [
  //       {
  //         name: "newInvoiceId",
  //         type: "hidden",
  //         viewConfig: "input",
  //         initialValue: newInvoiceId,
  //       },
  //     ],
  //   };
  // }, [newInvoiceId]);

  // const addInvoiceFormConfig = useMemo(() => {
  //   const fields = [
  //     {
  //       name: "contractId",
  //       fakeName: "contract",
  //       title: "Contract",
  //       type: "select",
  //       canCreate: false,
  //       isRequired: true,
  //       isReadOnly: false,
  //       validate: true,
  //       loading: contractStore.isPending,
  //       options: activeContractsArray,
  //     },
  //     {
  //       name: "currency",
  //       type: "hidden",
  //       viewConfig: "input",
  //       initialValue: currentBranch && currentBranch.currency,
  //     },
  //     {
  //       name: "methodId",
  //       fakeName: "method",
  //       title: "Payment method",
  //       type: "select",
  //       canCreate: true,
  //       isRequired: true,
  //       isReadOnly: false,
  //       validate: true,
  //       loading: currentBranch && currentBranch.isPending,
  //       options: currentBranch && currentBranch.paymentMethodsArray,
  //     },
  //     {
  //       name: "amountWOVAT",
  //       title: "Amount (without VAT)",
  //       type: "number",
  //       viewConfig: "input",
  //       inputMode: "decimal",
  //       min: 1,
  //       step: 0.01,
  //       startAdornment: currentBranch && currentBranch.currency,
  //       isRequired: true,
  //       isReadOnly: false,
  //       validate: true,
  //     },
  //     {
  //       name: "VAT",
  //       title: "VAT",
  //       type: "number",
  //       viewConfig: "input",
  //       inputMode: "decimal",
  //       min: 1,
  //       step: 0.01,
  //       startAdornment: "%",
  //       isReadOnly: true,
  //       initialValue: "0",
  //       countFunction: (data) => {
  //         if (!data.methodId) {
  //           return "";
  //         }
  //         const method = currentBranch && currentBranch.getPaymentMethodById(data.methodId);
  //         return method.vat;
  //       },
  //     },
  //     {
  //       name: "amountCounter",
  //       title: "Amount (with VAT)",
  //       type: "number",
  //       viewConfig: "input",
  //       inputMode: "decimal",
  //       min: 1,
  //       step: 0.01,
  //       startAdornment: currentBranch && currentBranch.currency,
  //       isReadOnly: true,
  //       isRequired: true,
  //       initialValue: "0",
  //       countFunction: (data) => {
  //         if (!(data.methodId && data.amountWOVAT)) {
  //           return 0;
  //         }
  //         const method = currentBranch && currentBranch.getPaymentMethodById(data.methodId);
  //         const vat = method ? method.vat : 0;
  //         const amountNumber = Number(data.amountWOVAT);
  //         const vatCount = (amountNumber * vat) / 100;
  //         return amountNumber + vatCount;
  //       },
  //     },
  //     {
  //       name: "amount",
  //       type: "hidden",
  //       viewConfig: "input",
  //       initialValue: "0",
  //       countFunction: (data) => {
  //         if (!(data.methodId && data.amountWOVAT)) {
  //           return 0;
  //         }
  //         const method = currentBranch && currentBranch.getPaymentMethodById(data.methodId);
  //         const vat = method ? method.vat : 0;
  //         const amountNumber = Number(data.amountWOVAT);
  //         const vatCount = (amountNumber * vat) / 100;
  //         return amountNumber + vatCount;
  //       },
  //     },
  //   ];

  //   return {
  //     submitText: "Create",
  //     cancelText: "Cancel",
  //     formTitle: "Create invoice",
  //     formText: "To create new invoice fill in the form below",
  //     fields,
  //   };
  // }, [
  //   currentBranch,
  //   currentBranch && currentBranch.isPending,
  //   currentBranch && currentBranch.paymentMethodsArra,
  //   contractStore.isPending,
  //   activeContractsArray,
  // ]);

  // const onOpenDialog = useCallback(() => {
  //   setOpen(true);
  //   setFormKind("invoice");
  // }, []);

  // const onCloseDialog = useCallback(
  //   (e, reason) => {
  //     if (reason === "backdropClick") return;
  //     setOpen(false);
  //   },
  //   [setOpen]
  // );

  // const onSubmit = useCallback(
  //   async (data) => {
  //     const result = false;
  //     try {
  //       setIsFormPending(true);
  //       setFormErrors({});
  //       if (formKind === "invoiceDownload") {
  //         await invoiceStore.downloadInvoice(data.newInvoiceId);
  //       } else if (formKind === "invoice") {
  //         const addedInvoice = await contractStore.addInvoiceForContractAsync(data);
  //         setNewInvoiceId(`${addedInvoice.id}`);
  //         setFormKind("invoiceDownload");
  //         setIsFormPending(false);
  //         return;
  //       }
  //     } catch (error) {
  //       rootStore.setError("error", null, error && error.message);
  //     }
  //     setIsFormPending(false);
  //     if (result === true) {
  //       setOpen(false);
  //       setNewInvoiceId("");
  //     }
  //   },
  //   [formKind, rootStore, invoiceStore]
  // );

  // const formConfigs = useMemo(() => {
  //   return {
  //     invoice: addInvoiceFormConfig,
  //     invoiceDownload: downloadInvoiceFormConfig,
  //   };
  // }, [addInvoiceFormConfig, downloadInvoiceFormConfig]);

  // const formConfig = useMemo(() => {
  //   return formConfigs[formKind];
  // }, [formKind, formConfigs]);

  const columns = useMemo(() => {
    const array = [
      {
        field: "number",
        headerName: languageStore.translate({ text: "Number" }),
        type: "string",
        width: 150,
      },
      {
        field: "createdAt",
        type: "date",
        headerName: languageStore.translate({ text: "Created at" }),
        width: 100,
        valueGetter: (value) => value?.toDate(),
        renderCell: ({ row }) => {
          return <Translator date={row?.createdAt} />;
        },
      },
      {
        field: "contractNumber",
        headerName: languageStore.translate({ text: "Contract" }),
        type: "string",
        width: 150,
      },
      {
        field: "clientInfo",
        headerName: languageStore.translate({ text: "Client" }),
        type: "string",
        width: 200,
        valueGetter: (value) => value?.name,
      },
      {
        field: "managerInfo",
        headerName: languageStore.translate({ text: "Manager" }),
        type: "string",
        width: 125,
        valueGetter: (value) => value?.name,
      },
      // {
      //   field: "clientName",
      //   headerName: <Translator text="Client" />,
      //   minWidth: 250,
      //   maxWidth: 350,
      //   flex: 1,
      // },
      // {
      //   field: "managerName",
      //   headerName: <Translator text="Manager" />,
      //   width: 150,
      // },

      // {
      //   field: "methodLabel",
      //   headerName: <Translator text="Payment method" />,
      //   width: 150,
      //   renderCell: (params) => {
      //     return <Translator text={params.row.methodLabel} />;
      //   },
      // },
      // {
      //   field: "vat",
      //   type: "number",
      //   headerName: <Translator text="VAT" />,
      //   width: 50,
      //   renderCell: (params) => {
      //     return <Translator number={params.row.method.vat} after={"%"} />;
      //   },
      // },
      {
        field: "amount",
        type: "number",
        headerName: languageStore.translate({ text: "Value" }),
        width: 125,
        renderCell: (params) => {
          return <Translator money={params.row.amount} />;
        },
      },
      {
        field: "vatAmount",
        type: "number",
        headerName: languageStore.translate({ text: "VAT Amount" }),
        width: 125,
        renderCell: (params) => {
          return <Translator money={params.row.vatAmount} />;
        },
      },
      {
        field: "valueWithoutVAT",
        type: "number",
        headerName: languageStore.translate({ text: "Value without VAT" }),
        width: 125,
        renderCell: (params) => {
          return <Translator money={params.row.valueWithoutVAT} />;
        },
      },
      {
        field: "method",
        type: "string",
        headerName: languageStore.translate({ text: "Method" }),
        width: 150,
        renderCell: (params) => {
          return params.row.methodLabel;
        },
      },
      {
        field: "payedAmount",
        type: "number",
        headerName: languageStore.translate({ text: "Payed" }),
        width: 125,
        renderCell: (params) => {
          return <Translator money={params.row.payedAmount} />;
        },
      },
      {
        field: "valueVAT",
        type: "number",
        headerName: languageStore.translate({ text: "Value VAT" }),
        width: 125,
        renderCell: (params) => {
          return <Translator money={params.row.valueVAT} />;
        },
      },
      {
        field: "payedAmountWithoutVAT",
        type: "number",
        headerName: languageStore.translate({ text: "Payed without VAT" }),
        width: 125,
        renderCell: (params) => {
          return <Translator money={params.row.payedAmountWithoutVAT} />;
        },
      },
      {
        field: "payments",
        type: "string",
        headerName: languageStore.translate({ text: "Payment date" }),
        width: 150,
        valueGetter: (value) => {
          const dates = value.map((p) => languageStore.translate({ date: p.date }));
          return dates.join(" ");
        },
      },
      // {
      //   field: "paymentsArray",
      //   headerName: <Translator text="Payments" />,
      //   width: 500,
      //   renderCell: (params) => {
      //     return <BarChart key={`${params.row.payedAmount}-${params.row.id}`} id={params.row.id} />;
      //   },
      // },
    ];

    // if (permissionsSet.has(`payments.add_payment`)) {
    array.push({
      field: "actions",
      headerName: languageStore.translate({ text: "Actions" }),
      width: 200,
      renderCell: (params) => {
        return <AddPayment invoice={params.row} />;
      },
    });
    // }

    array.push({
      field: "downloadInvoice",
      headerName: <Translator text="Download invoice" />,
      width: 200,
      renderCell: (params) => {
        return <DownloadInvoice invoiceId={params.row.id} />;
      },
    });

  //   if (permissionsSet.has(`payments.delete_invoice`)) {
  //     array.push({
  //       field: "deleteInvoice",
  //       headerName: <Translator text="Delete invoice" />,
  //       width: 200,
  //       renderCell: (params) => {
  //         return <DeleteInvoice invoice={params.row} />;
  //       },
  //     });
  //   }

    return array;
  }, [languageStore]);

  // функция раскраски ячейки
  const getCellClassName = (params) => {
    const { field, value, row } = params;
    const { amount } = row;
    let className = "";
    if (field === "payedAmount") {
      if (value === amount) {
        className += "green-cell"; // оплачено точно
      } else if (value > amount) {
        className += "red-cell"; // переплата, надо разбираться
      } else if (value > 0) {
        className += "orange-cell"; // оплачен частично
      }
    }
    return className;
  };

  return (
    <Module>
      <Toolbar disableGutters={true} sx={{ mt: 0.5 }}>
        <Tooltip arrow title={<Translator text="amount" />}>
          <Box sx={{ ml: 1, fontWeight: "bold", fontSize: "large", minWidth: 125 }}>
            <Translator number={invoiceStore.summary.amount || "0"} currency={branch?.currency} />
          </Box>
        </Tooltip>
        <Tooltip arrow title={<Translator text="paid" />}>
          <Box sx={{ ml: 1, fontWeight: "bold", fontSize: "large", color: "#428c00", minWidth: 125 }}>
            <Translator number={invoiceStore.summary.paid || "0"} currency={branch?.currency} />
          </Box>
        </Tooltip>
        <Tooltip arrow title={<Translator text="debt" />}>
          <Box sx={{ ml: 1, fontWeight: "bold", fontSize: "large", color: "#8f2212", minWidth: 125 }}>
            <Translator number={invoiceStore.summary.debt || "0"} currency={branch?.currency} />
          </Box>
        </Tooltip>
        <SearchField label={<Translator text={"Search invoices..."} />} onSearch={onSearch} />
        <StatesSelect
          options={["paid", "not-paid", "partially-paid", "overpaid"]}
          value={selectedStates}
          onSelect={onSelectStates}
        />
      </Toolbar>
      <DataGrid
        rows={invoicesArray}
        columns={columns}
        initialState={{
          pagination: { paginationModel: { pageSize: 15, page: 0 } },
          //   sorting: {
          //     sortModel: [{ field: "createdAt", sort: "desc" }],
          //   },
        }}
        // apiRef={gridApi}
        disableColumnFilter // фильтры клиентские, убрать
        disableColumnSorting
        stickyHeader={true}
        autoPageSize={false}
        pageSizeOptions={[10, 15, 20, 25, 50, 100]}
        pagination={true}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        rowCount={invoiceStore.invoiceTotalCount}
        loading={isPending}
        autoHeight={false}
        getRowHeight={() => "auto"}
        getEstimatedRowHeight={() => 52}
        getCellClassName={getCellClassName}
        sx={{
          "& .MuiDataGrid-cell": {
            // чтобы был отступ в ячейке при использовании динамической её высоты
            padding: 1,
            // чтобы текст был по вернхнему краю
            alignItems: "baseline",
          },
        }}
        slotProps={
          {
            // row: {
            //   onContextMenu: handleContextMenu,
            //   style: { cursor: "context-menu" },
            // },
          }
        }
      />
      {/* <Fab
        onClick={onOpenDialog}
        key="fab"
        sx={{
          position: "fixed",
          bottom: 32,
          right: 32,
        }}
        color="primary"
        aria-label="FilterList"
      >
        <Add />
      </Fab> */}
      {/* <Dialog open={open} onClose={onCloseDialog}>
        {!isPending && (
          <Form
            config={formConfig}
            onSubmit={onSubmit}
            onCancel={onCloseDialog}
            isPending={isFormPenfing}
            errors={formErrors}
          />
        )}
      </Dialog> */}
    </Module>
  );
});

export default Invoices;
